<template>
  <div>
    <!-- 頁面: 金流列表(權限: 完成, 註解: 刪除, 優化: 無) -->
    <div v-if="checkAuthAbility('payment', 'API.Admin.Payment')">
      <!-- 新增金流商 -->
      <payment-list-add-modal
        ref="paymentAddModal"
        @refetch-data="refetchData"
      />

      <!-- 金流商編輯 -->
      <payment-list-edit-modal
        v-if="selected"
        ref="paymentEditModal"
        :payment-item="selected"
        @refetch-data="refetchData"
      />

      <!-- 搜尋欄 -->
      <b-card no-body>
        <b-card-header class="pb-50">
          <h5>篩選器</h5>
        </b-card-header>

        <b-card-body>
          <b-row>
            <b-col
              cols="12"
              lg="2"
              md="3"
              class="mb-md-0 mb-1"
            >
              <label>狀態</label>
              <v-select
                v-model="searchState"
                :options="stateOptions"
                class="w-100"
                :reduce="option => option.value"
              >
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    沒有<em>{{ search }}</em> 的相關結果
                  </template>

                  <div v-else>
                    <small>暫無資料</small>
                  </div>
                </template>

                <template v-slot:option="option">
                  <div class="d-flex align-items-center">
                    <span
                      class="mr-50 border state-dot"
                      :class="{ 'dot-gray': !option.value, 'dot-green-selected': option.value }"
                    />
                    {{ option.label }}
                  </div>
                </template>

                <template #selected-option="option">
                  <div class="d-flex align-items-center">
                    <span
                      class="mr-50 border state-dot"
                      :class="{ 'dot-gray': !option.value, 'dot-green-selected': option.value }"
                    />
                    {{ option.label }}
                  </div>
                </template>
              </v-select>
            </b-col>

            <b-col
              cols="12"
              lg="3"
              md="4"
              class="mb-md-0 mb-1"
            >
              <label>金流商</label>
              <v-select
                v-model="searchThirdPartyId"
                :options="adminPaymentState.paymentTypeOptions"
                class="w-100"
                label="name"
                :reduce="option => option.id"
              >
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    沒有<em>{{ search }}</em> 的相關結果
                  </template>

                  <div v-else>
                    <small>暫無資料</small>
                  </div>
                </template>
              </v-select>
            </b-col>

            <b-col
              cols="12"
              lg="3"
              md="4"
              class="mb-md-0 mb-1"
            >
              <label>類別</label>
              <v-select
                v-model="searchType"
                :options="typeOptions"
                class="w-100"
                :reduce="option => option.value"
              >
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    沒有<em>{{ search }}</em> 的相關結果
                  </template>

                  <div v-else>
                    <small>暫無資料</small>
                  </div>
                </template>
              </v-select>
            </b-col>
          </b-row>

        </b-card-body>
      </b-card>

      <!-- 金流列表 -->
      <b-card no-body>
        <div class="mx-2 mt-2 mb-50">
          <b-row>
            <b-col
              cols="12"
              md="8"
              sm="6"
            >
              <h4 class="mb-0">
                金流列表
              </h4>
            </b-col>

            <b-col
              cols="12"
              md="4"
              sm="6"
            >
              <div class="text-nowrap d-flex justify-content-end">
                <div
                  v-if="checkAuthAbility('payment', 'API.Admin.Payment.Create')"
                  v-b-tooltip.hover.focus.v-secondary
                  title="新增"
                  class="d-flex align-items-center actions-link-btn"
                  @click="onSubmitUpdate(null)"
                >
                  <b-img
                    src="/dashboard/admin/images/table/plus.svg"
                    class="actions-link-btn-image"
                    rounded
                  />
                </div>

                <div
                  v-b-tooltip.hover.focus.v-secondary
                  title="重新整理"
                  class="d-flex align-items-center actions-link-btn ml-25"
                  @click="refetchTable"
                >
                  <b-img
                    src="/dashboard/admin/images/table/refresh.svg"
                    class="actions-link-btn-image"
                    rounded
                  />
                </div>

                <div
                  v-b-tooltip.hover.focus.v-secondary
                  title="列表顯示"
                  class="d-flex align-items-center actions-link-btn ml-25"
                >
                  <b-dropdown
                    variant="link"
                    toggle-class="p-0"
                    no-caret
                    right
                  >
                    <template #button-content>
                      <b-img
                        src="/dashboard/admin/images/table/setting.svg"
                        class="actions-link-btn-image"
                        rounded
                      />
                    </template>

                    <b-dropdown-form>
                      <b-form-group>
                        <label class="mb-50">
                          列表顯示
                        </label>

                        <b-form-checkbox
                          v-for="column in tableColumnsSearchable"
                          :key="column.key"
                          v-model="column.select"
                          name="table-column"
                          class="mb-1"
                        >
                          {{ column.label }}
                        </b-form-checkbox>
                      </b-form-group>
                    </b-dropdown-form>

                  </b-dropdown>
                </div>

                <div
                  v-b-tooltip.hover.focus.v-secondary
                  title="創建日期"
                  class="d-flex align-items-center actions-link-btn ml-25"
                  :class="timeRange ? 'actions-link-btn-active' : null"
                  @click="() => { $refs.TableTimeRange.getData(timeRange) }"
                >
                  <b-img
                    src="/dashboard/admin/images/table/calendar.svg"
                    class="actions-link-btn-image"
                    rounded
                  />

                  <TableTimeRange
                    ref="TableTimeRange"
                    table-explorer-id="TableTimeRange"
                    @call-back-data="time => timeRange = time"
                  />
                </div>
              </div>
            </b-col>

          </b-row>
        </div>

        <!-- 顯示個數/搜尋框 -->
        <div class="mx-2 mb-2">
          <b-row>
            <!-- 顯示個數 -->
            <b-col
              cols="12"
              md="6"
              sm="4"
              class="d-flex align-items-center justify-content-start mb-1 mb-sm-0"
            >
              <v-select
                v-model="perPage"
                :options="perPageOptions"
                :clearable="false"
                class="table-perPage-select"
              >
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    沒有<em>{{ search }}</em> 的相關結果
                  </template>

                  <div v-else>
                    <small>暫無資料</small>
                  </div>
                </template>
              </v-select>
            </b-col>

            <!-- 搜尋框 -->
            <b-col
              cols="12"
              md="6"
              sm="8"
            >
              <!-- 搜尋框 -->
              <div class="w-100 mr-1">
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="SearchIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    v-model="searchQuery"
                    debounce="500"
                    class="d-inline-block"
                    placeholder="搜尋..."
                  />
                  <b-input-group-append
                    v-if="searchQuery"
                    is-text
                  >
                    <feather-icon
                      icon="XIcon"
                      class="cursor-pointer text-muted"
                      @click="searchQuery = null"
                    />
                  </b-input-group-append>
                </b-input-group>
              </div>
            </b-col>

          </b-row>
        </div>

        <!-- 列表 -->
        <b-table
          ref="refDataListTable"
          :items="getPaymentListData"
          :fields="tableColumnsFilter"
          responsive
          striped
          hover
          primary-key="id"
          :sort-by.sync="sortBy"
          :sort-desc.sync="isSortDirDesc"
          show-empty
          class="position-relative"
          details-td-class="p-0"
          :stacked="$store.getters['app/currentBreakPoint'] === 'xs'"
        >
          <!-- 忙碌中 -->
          <template #table-busy>
            <b-skeleton-table
              :rows="5"
              :columns="tableColumnsFilter.length"
              hide-header
              :table-props="{ bordered: true, striped: true }"
            />
          </template>

          <!-- 查無資料 -->
          <template #empty>
            <div class="text-center my-5 animate__animated animate__fadeIn">
              <b-img
                :src="$store.state.app.themeImages.notFoundImg"
                fluid
                width="480"
                alt="查無資料"
              />
            </div>
          </template>

          <!-- 欄位: 編號 -->
          <template #cell(id)="data">
            <div class="table-col">
              <span>{{ data.item.id }}</span>
            </div>
          </template>

          <!-- 欄位: 權限名稱 -->
          <template #cell(name)="data">
            <div class="d-flex align-items-center">
              <div class="w-100">
                <b-link
                  class="font-weight-bold d-block text-nowrap show-text"
                  @click="onSubmitUpdate(data.item)"
                >
                  {{ data.item.name }}
                </b-link>
                <small class="text-muted">
                  <div class="email-text">{{ resolveThirdPartyId(data.item.third_party_id) }}</div>
                </small>
              </div>
            </div>
          </template>

          <!-- 欄位: 狀態 -->
          <template #cell(state)="data">
            <div class="table-col">
              <div
                v-if="data.item.state"
                v-b-tooltip.hover.focus.v-secondary
                title="啟用"
                class="state-dot dot-green"
                @click="onSubmitUpdate(data.item)"
              />
              <div
                v-else
                v-b-tooltip.hover.focus.v-secondary
                title="停用"
                class="state-dot dot-gray"
                @click="onSubmitUpdate(data.item)"
              />
            </div>
          </template>

          <!-- 欄位: 類別 -->
          <template #cell(type)="data">
            <div class="table-col">
              <b-badge
                v-if="data.item.type"
                :variant="ui.typeList[1][data.item.type]"
                @click="onSubmitUpdate(data.item)"
              >
                {{ ui.typeList[0][data.item.type] }}
              </b-badge>

              <span v-else>---</span>
            </div>
          </template>

          <!-- 欄位: 單日限額 -->
          <template #cell(daily_total)="data">
            <div class="d-flex align-items-center table-col">
              <div class="font-weight-bold text-body-heading mr-50 text-nowrap">
                <span v-if="data.item.daily">{{ data.item.percentagedaily }} %</span>
                <span v-else>不設限</span>
              </div>
              <vue-apex-charts
                v-b-tooltip.hover.focus.v-secondary
                :title="`${parseInt(data.item.daily_total, 10).toLocaleString()} / ${data.item.daily ? parseInt(data.item.daily, 10).toLocaleString() : '不設限'}`"
                type="radialBar"
                height="30"
                width="30"
                :options="data.item.chartdailyData.options"
                :series="data.item.chartdailyData.series"
              />
            </div>
          </template>

          <!-- 欄位: 單月限額  -->
          <template #cell(monthly_total)="data">
            <div class="d-flex align-items-center table-col">
              <div class="font-weight-bold text-body-heading mr-50 text-nowrap">
                <span v-if="data.item.monthly">{{ data.item.percentageMonthly }} %</span>
                <span v-else>不設限</span>
              </div>
              <vue-apex-charts
                v-b-tooltip.hover.focus.v-secondary
                :title="`${parseInt(data.item.monthly_total, 10).toLocaleString()} / ${data.item.monthly ? parseInt(data.item.monthly, 10).toLocaleString() : '不設限'}`"
                type="radialBar"
                height="30"
                width="30"
                :options="data.item.chartMonthlyData.options"
                :series="data.item.chartMonthlyData.series"
              />
            </div>
          </template>

          <!-- 欄位: 單筆下限 -->
          <template #cell(low)="data">
            <div class="table-col text-nowrap">
              <span v-if="data.item.low">$ {{ parseInt(data.item.low, 10).toLocaleString() }}</span>
              <span v-else>---</span>
            </div>
          </template>

          <!-- 欄位: 單筆上限 -->
          <template #cell(high)="data">
            <div class="table-col text-nowrap">
              <span v-if="data.item.high">$ {{ parseInt(data.item.high, 10).toLocaleString() }}</span>
              <span v-else>---</span>
            </div>
          </template>

          <!-- 欄位: 創建時間 -->
          <template #cell(created_at)="data">
            <div style="min-width: 85px;">
              <div v-if="data.item.created_at">
                <span
                  v-b-tooltip.hover.focus.v-secondary
                  :title="`${moment(data.item.created_at).format('HH:mm:ss')}`"
                >
                  {{ updateOnline(data.item.created_at, refonlineTime) }}
                </span>
              </div>

              <div v-else>
                <span>---</span>
              </div>
            </div>
          </template>

          <!-- 欄位: 更新時間 -->
          <template #cell(updated_at)="data">
            <div style="min-width: 85px;">
              <div v-if="data.item.updated_at">
                <span
                  v-b-tooltip.hover.focus.v-secondary
                  :title="`${moment(data.item.updated_at).format('HH:mm:ss')}`"
                >
                  {{ updateOnline(data.item.updated_at, refonlineTime) }}
                </span>
              </div>

              <div v-else>
                <span>---</span>
              </div>
            </div>
          </template>

          <!-- 欄位: 動作 -->
          <template #cell(actions)="data">
            <div class="d-flex mb-50">

              <div
                v-if="checkAuthAbility('payment', 'API.Admin.Payment.Create')"
                class="actions-link-btn mr-25"
                @click="onSubmitCopy(data.item)"
              >
                <b-img
                  v-b-tooltip.hover.focus.v-secondary
                  title="複製"
                  src="/dashboard/admin/images/table/copy.svg"
                  class="actions-link-btn-image"
                  rounded
                />
              </div>

              <div
                v-if="checkAuthAbility('payment', 'API.Admin.Payment.Update') && checkAuthAbility('payment', 'API.Admin.Payment.Id')"
                class="actions-link-btn"
                @click="onSubmitUpdate(data.item)"
              >
                <b-img
                  v-b-tooltip.hover.focus.v-secondary
                  title="編輯"
                  src="/dashboard/admin/images/table/edit.svg"
                  class="actions-link-btn-image"
                  rounded
                />
              </div>
            </div>
          </template>
        </b-table>

        <!-- 顯示頁數/分頁 -->
        <div class="m-2">
          <b-row>
            <!-- 顯示頁數 -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">第 {{ dataMeta.from }} - {{ dataMeta.to }} 筆，共 {{ dataMeta.of }} 筆</span>
            </b-col>

            <!-- 分頁 -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalNum"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>

      </b-card>
    </div>

    <div v-else>
      <b-alert
        variant="danger"
        :show="true"
      >
        <h4 class="alert-heading">
          權限不足
        </h4>
        <div class="alert-body">
          返回
          <b-link
            class="alert-link"
            :to="{ name: 'admin-home'}"
          >
            後台首頁
          </b-link>
        </div>
      </b-alert>
    </div>
  </div>
</template>

<script>
import { onUnmounted } from '@vue/composition-api'
import moment from 'moment'
import {
  BCard, BRow, BTable, BCol, BPagination, VBTooltip, BDropdown, BLink, BBadge, BInputGroupAppend,
  BDropdownForm, BFormInput, BInputGroupPrepend, BImg, BCardHeader, BAlert,
  BInputGroup, BFormGroup, BFormCheckbox, BCardBody, BSkeletonTable,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import VueApexCharts from 'vue-apexcharts'
import TableTimeRange from '@/layouts/components/Table/TimeRange.vue'
import router from '@/router'
import store from '@/store'
import { usePaymentList, usePaymentSetting } from '../usePayment'
import useStoreModule from '../useStoreModule'
import PaymentListAddModal from './PaymentListAddModal.vue'
import PaymentListEditModal from './PaymentListEditModal.vue'
import { usePublicFunction } from '@/libs/mixins/index'

export default {
  metaInfo() {
    return {
      title: this.title ? this.title : store.state['app-config'].app.appName,
      titleTemplate: `%s - ${store.state.app.titleTemplate}`,
    }
  },
  components: {
    BCard,
    BRow,
    BCol,
    BImg,
    BLink,
    BAlert,
    BBadge,
    BFormInput,
    BTable,
    BPagination,
    BDropdown,
    BDropdownForm,
    BCardHeader,
    BInputGroupPrepend,
    BInputGroupAppend,
    BInputGroup,
    BFormGroup,
    BFormCheckbox,
    BCardBody,
    BSkeletonTable,

    vSelect,
    TableTimeRange,
    VueApexCharts,
    PaymentListAddModal,
    PaymentListEditModal,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [usePublicFunction],
  data() {
    return {
      title: router.currentRoute.meta.pageTitle,
      selected: null,
    }
  },
  computed: {
    tableColumnsFilter() {
      return this.tableColumns
        .filter(f => f.select)
    },
    tableColumnsSearchable() {
      return this.tableColumns
        .filter(f => f.searchable)
    },
    adminPaymentState() {
      return this.$store.state['admin-payment']
    },
  },
  mounted() {
    this.timer = setInterval(this.onlineTime, 1000)
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    moment,
    // (轉換)第三方支付名稱
    resolveThirdPartyId(id) {
      const item = this.adminPaymentState.paymentTypeOptions.find(f => f.id === id)
      return item ? item.name : id
    },

    // (觸發)複製
    onSubmitCopy(item) {
      const auth = this.checkAuthAbility('payment', 'API.Admin.Payment.Create')
      if (!auth) return
      const copyItem = {
        name: `${item.name}-複製`,
        state: item.state,
        third_party_id: item.third_party_id,
        type: item.type,
        daily: item.daily,
        monthly: item.monthly,
        high: item.high,
        low: item.low,
        config: [],
      }

      if (item.config && item.config.length > 0) {
        copyItem.config = item.config.map(el => {
          const resolveItem = this.syncObject({
            key: null,
            label: null,
            value: null,
          }, el)
          return resolveItem
        })
      }

      setTimeout(() => { this.$refs.paymentAddModal.getData(copyItem) }, 200)
    },

    // (觸發)新增/編輯
    onSubmitUpdate(item) {
      if (!item) {
        const auth = this.checkAuthAbility('payment', 'API.Admin.Payment.Create')
        if (!auth) return
        this.$refs.paymentAddModal.getData()
        return
      }
      const auth = this.checkAuthAbility('payment', 'API.Admin.Payment.Update') && this.checkAuthAbility('payment', 'API.Admin.Payment.Id')
      if (!auth) return
      this.selected = item
      setTimeout(() => { this.$refs.paymentEditModal.getData() }, 200)
    },
  },
  setup() {
    const PAYMENT_ADMIN_STORE_MODULE_NAME = 'admin-payment'

    if (!store.hasModule(PAYMENT_ADMIN_STORE_MODULE_NAME)) store.registerModule(PAYMENT_ADMIN_STORE_MODULE_NAME, useStoreModule)

    onUnmounted(() => {
      if (store.hasModule(PAYMENT_ADMIN_STORE_MODULE_NAME)) store.unregisterModule(PAYMENT_ADMIN_STORE_MODULE_NAME)
    })
    const {
      ui,
      stateOptions,
      typeOptions,
      refonlineTime,
      onlineTime,
      updateOnline,
      syncObject,
    } = usePaymentSetting()

    const {
      getPaymentListData,
      setPaymentDelete,
      setPaymentUpdate,
      getPaymentTypeData,
      setPaymentTestUpdate,
      refDataListTable,
      tableColumns,
      perPage,
      currentPage,
      totalNum,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      timeRange,
      isSortDirDesc,
      searchState,
      searchType,
      searchThirdPartyId,
      paymentOptions,
      refetchData,
      refetchTable,
      dateDefaluteConfig,
    } = usePaymentList()

    getPaymentTypeData({ key: 'thirdPartyPayment' }, () => {})

    return {
      ui,
      stateOptions,
      typeOptions,
      refonlineTime,
      onlineTime,
      updateOnline,
      syncObject,
      refDataListTable,
      dateDefaluteConfig,
      tableColumns,
      perPage,
      currentPage,
      totalNum,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      timeRange,
      isSortDirDesc,
      searchState,
      searchType,
      searchThirdPartyId,
      paymentOptions,
      refetchData,
      refetchTable,
      getPaymentListData,
      setPaymentDelete,
      setPaymentUpdate,
      setPaymentTestUpdate,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.table-col {
  min-width: 40px;
}

.table-title {
  min-width: 100px;
}

.table-image {
  min-width: 90px;
  max-width: 100%;
  max-height: 30px;
}

.item-description {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 150px;
}
.item-description-email {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100px !important;
  font-size: 12px;
}
.server-name:hover {
  color: red !important;
  text-decoration: underline
}

.border-search {
  display: flex;
  flex-wrap: wrap;
}

.selection-group {
  .selection-btn-icon-show {
    opacity: 0;
    margin-left: 3px;
  }
}

.selection-group:hover {
  .selection-btn-icon-show {
    opacity: 1;
  }
}

</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style lang="scss" scoped>
.dot-green-selected {
  background:radial-gradient(circle closest-side at center,#66ff66,#1bb425);
}

.dot-yellow-selected {
  background:radial-gradient(circle closest-side at center,#f6e9ad,#f6bc01);
}

.dot-red-selected {
  background:radial-gradient(circle closest-side at center,#f6adad,#f32929);
}
</style>
