<template>
  <div>
    <!-- 創建日期 -->
    <b-modal
      :id="tableExplorerId"
      centered
      hide-footer
      :header-bg-variant="componentTheme"
      @hidden="handleOK"
      @close="handleOK"
    >
      <!-- no-close-on-backdrop -->
      <template #modal-title>
        <h4 class="m-0 model-header">
          {{ componentTitle }}
        </h4>
      </template>

      <div class="mb-1">
        <div
          v-if="state"
          class="animate__animated animate__fadeIn"
        >
          <div class="mb-50">
            <b-button
              size="sm"
              variant="outline-primary"
              class="mr-50 mb-25"
              @click="quickTime(0, 'd')"
            >
              今天
            </b-button>
            <b-button
              size="sm"
              variant="outline-primary"
              class="mr-50 mb-25"
              @click="quickTime(-1, 'd')"
            >
              昨天
            </b-button>
            <b-button
              size="sm"
              variant="outline-primary"
              class="mr-50 mb-25"
              @click="quickTime(-7, 'd')"
            >
              上周
            </b-button>
            <b-button
              size="sm"
              variant="outline-primary"
              class="mr-50 mb-25"
              @click="quickTime(-14, 'd')"
            >
              上兩周
            </b-button>

            <b-button
              size="sm"
              variant="outline-primary"
              class="mr-50 mb-25"
              @click="getThisMonthRange"
            >
              本月
            </b-button>

            <b-button
              size="sm"
              variant="outline-secondary"
              class="mr-50 mb-25"
              @click="clearSearchTime"
            >
              清空
            </b-button>
          </div>

          <v-date-picker
            v-model="range"
            mode="date"
            :masks="masksTime"
            is-range
            :is-dark="$store.state.appConfig.layout.skin === 'dark'"
            is-expanded
          />

          <div class="mt-1">
            <div class="d-none d-lg-block">
              <div class="time-picker-lg-area">
                <div class="time-picker-only">
                  <flat-pickr
                    v-model="range.start"
                    class="form-control"
                    :config="dateConfigLg"
                  />
                </div>

                ~

                <div class="time-picker-only">
                  <flat-pickr
                    v-model="range.end"
                    class="form-control"
                    :config="dateConfigLg"
                  />
                </div>
              </div>
            </div>

            <div class="d-lg-none d-block">
              <div class="time-picker-sm-area">
                <div class="time-picker-only">
                  <flat-pickr
                    v-model="range.start"
                    class="form-control"
                    :config="dateConfigSm"
                  />
                </div>

                <div class="mx-50">
                  ~
                </div>

                <div class="time-picker-only">
                  <flat-pickr
                    v-model="range.end"
                    class="form-control"
                    :config="dateConfigSm"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          v-else
          class="loading-area"
        >
          <b-img
            :src="$store.state.app.themeImages.loadingImg"
            rounded
            height="60"
            width="60"
          />
        </div>
      </div>

    </b-modal>
  </div>
</template>

<script>
import {
  BModal, VBTooltip, BImg, BButton,
} from 'bootstrap-vue'
import moment from 'moment'
import Ripple from 'vue-ripple-directive'
import language from 'flatpickr/dist/l10n/zh-tw'
import flatPickr from 'vue-flatpickr-component'

import { useSwalToast } from '@/libs/mixins/index'

export default {
  components: {
    BImg,
    BModal,
    BButton,
    flatPickr,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  mixins: [useSwalToast],
  props: {
    componentTitle: {
      type: String,
      default: '創建日期',
    },
    componentTheme: {
      type: String,
      default: 'info',
    },
    tableExplorerId: {
      type: String,
      default: 'table-time-range',
    },
  },
  data() {
    return {
      range: {
        start: null,
        end: null,
      },
      masksTime: {
        input: 'YYYY-MM-DD h:mm',
      },
      state: false,
    }
  },
  methods: {
    moment,
    // (獲取)本月
    getThisMonthRange() {
      const today = new Date()
      const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1)
      const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0)

      // 设置日期对象
      const date = {
        start: firstDayOfMonth,
        end: lastDayOfMonth,
      }
      this.specifiedTime(date.start, date.end)
    },

    // (跳轉至)指定日期
    specifiedTime(start, end) {
      let startDate = start instanceof Date ? new Date(start.getTime()) : new Date()
      let endDate = end instanceof Date ? new Date(end.getTime()) : new Date()

      if (this.maxDateTime && endDate > new Date(this.maxDateTime)) {
        endDate = new Date(this.maxDateTime)
      }

      if (this.minDateTime && startDate < new Date(this.minDateTime)) {
        startDate = new Date(this.minDateTime)
      }

      startDate = this.formatDateTime(startDate)
      endDate = this.formatDateTime(endDate)

      this.range = {
        ...this.range,
        start: startDate,
        end: endDate,
      }
    },

    // (跳轉至)指定日期
    quickTime(time, unit) {
      const timeInterval = unit === 'h' ? time * 60 * 60 * 1000 : time * 24 * 60 * 60 * 1000
      const currentDate = new Date()

      let startDate = new Date()
      let endDate = new Date()

      if (time >= 0) {
        endDate = this.formatDateTime(new Date(currentDate.getTime() + timeInterval))
        startDate = this.formatDateTime(currentDate)
      } else {
        startDate = this.formatDateTime(new Date(currentDate.getTime() + timeInterval))
        endDate = this.formatDateTime(currentDate)
      }

      this.range = {
        ...this.range,
        start: startDate,
        end: endDate,
      }
    },

    // (清除)搜尋時間
    clearSearchTime() {
      if (!this.range.start && !this.range.end) return
      this.state = false
      this.range.start = null
      this.range.end = null
      setTimeout(() => {
        this.initDataTime(null)
      }, 100)
    },

    // (標轉化)日期時間
    formatDateTime(inputDate) {
      const date = new Date(inputDate)
      const year = date.getFullYear()
      const month = String(date.getMonth() + 1).padStart(2, '0')
      const day = String(date.getDate()).padStart(2, '0')
      const hours = String(date.getHours()).padStart(2, '0')
      const minutes = String(date.getMinutes()).padStart(2, '0')

      return `${year}-${month}-${day} ${hours}:${minutes}`
    },

    inputDateTime() {
      let resolveTimeRange = null
      if (!this.range.start && !this.range.end) {
        this.$emit('call-back-data', null)
        return
      }
      const formattedStart = this.formatDateTime(this.range.start)
      if (this.range.end) {
        const formattedEnd = this.formatDateTime(this.range.end)
        resolveTimeRange = `${formattedStart} to ${formattedEnd}`
      } else resolveTimeRange = `${formattedStart}`
      this.$emit('call-back-data', resolveTimeRange)
    },

    // 按下彈窗關閉
    handleOK(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.inputDateTime()
      this.$nextTick(() => {
        this.$bvModal.hide(this.tableExplorerId)
        setTimeout(() => {
          this.range.start = null
          this.range.end = null
        }, 200)
      })
    },

    // (初始化)日期時間
    initDataTime(initData) {
      this.state = false
      const dateRangeString = initData
      if (!dateRangeString) {
        this.state = true
        return
      }

      const [startString, endString] = dateRangeString.split(' to ')

      const startDate = this.formatDateTime(startString)
      const endDate = this.formatDateTime(endString)

      this.range = {
        ...this.range,
        start: startDate,
        end: endDate,
      }
      this.state = true
    },

    // (獲取資料)
    getData(timeRangeData) {
      this.$bvModal.show(this.tableExplorerId)
      this.initDataTime(timeRangeData)
    },
  },
  setup() {
    const dateConfigLg = {
      enableTime: true,
      noCalendar: true,
      dateFormat: 'Y-m-d H:i',
      inline: true,
      locale: language.zh_tw,
    }

    const dateConfigSm = {
      enableTime: true,
      noCalendar: true,
      dateFormat: 'Y-m-d H:i',
      locale: language.zh_tw,
    }

    return {
      dateConfigLg,
      dateConfigSm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.time-picker-only {
  .flatpickr-calendar {
    max-width: 200px;
    .flatpickr-time {
      border-top: none;
    }
  }
}
</style>

<style lang="scss" scoped>
.model-header {
  color: white;
  padding: 5px 0;
}

.time-picker-lg-area {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .time-picker-only {
    .flatpickr-input {
      display: none;
    }

    .flatpickr-time {
      border-top: none;
    }
  }
}

.time-picker-sm-area {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.loading-area {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 300px;
}
</style>
